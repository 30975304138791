import React from "react";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Products from "~components/products/products";
import "~styles/pages/services/_digital-advertising.scss";
import TypeAnimation from "react-type-animation";

const steps = [
  "Digital Advertising Campaign Management",
  500,
  "Digital Advertising Campaign Growth Marketing",
  500,
  "Digital Advertising Campaign SEM and PPC Management",
  500,
  "Digital Advertising Campaign Social Advertising Campaigns",
  500,
  "Whatever You Call It.",
  500,
  "Digital Advertising",
  500,
];

const ContentPage = () => (
  <Layout>
    <SEO
      title="Digital and Social Advertising Services"
      description="Digital Advertising can be overwhelming and time-consuming. We’re here to help with creative, design, and strategic planning to meet your goals and budget. "
    />
    <section className="page-heading">
      <div className="container">
        <TypeAnimation
          cursor={false}
          sequence={steps}
          wrapper="h1"
          className={"title"}
        />
        <div className="digital-advertising-header p-mb">
          <p>
            Whatever you call it, the goal is always going to be one or more of
            the following: You want more customers. You want to keep your
            customers loyal. You want to have your customers spend more money
            with you. We do those things for your brand and business. And we do
            them through digital advertising channels that are measurable,
            scalable, and targeted.
          </p>
          <p>
            Included in all options is the campaign planning, creative
            direction, design/production, budget & channel recommendations for
            successful paid digital advertising campaigns. This is inclusive of
            the creative thinking, project management, copywriting, graphic
            design, creative skills, campaign management and monitoring needed
            to complete the deliverables and ensure a successful paid strategy.
          </p>
        </div>
      </div>
    </section>
    <section className="web-it-support">
      <div className="container">
        <Products>
          <div
            title="Small Ads Package"
            subtitle="$1,700 CAD per month + ad spend"
            extrainfo="+ one-time $625 CAD launch fee"
            id="advertising-small"
          >
            <p>
              The Small Ads Package is designed for brands and businesses that
              are looking to dip their toes in the water with paid media in a
              strategic way. This package is excellent for initial audience,
              market or product testing, and can be designed for an ad spend of
              anywhere from $1,000 CAD - $3,500 CAD per month.
            </p>
          </div>
          <div
            title="Medium Ads Package"
            subtitle="$2,960 CAD per month + ad spend"
            extrainfo="+ one-time $625 CAD launch fee"
            id="advertising-medium"
          >
            <p>
              The Medium Ads Package is created for brands and businesses that
              want to accelerate their growth, find new audiences, launch a new
              brand, product or service and/or re-engage past or potential
              customers. This package can be designed for an ad spend of
              anywhere from $5,000 CAD - $15,000 CAD per month.
            </p>
          </div>
          <div
            title="Large Ads Package"
            subtitle="$4,080 CAD per month + ad spend"
            extrainfo="+ one-time $625 CAD launch fee"
            id="advertising-large"
          >
            <p>
              The Large Ads Package is tailored for brands and businesses that
              are ready to take the next step in their growth plan, find & grow
              new or undiscovered audiences or enter a new market. This is also
              an ideal package for businesses that have plateaued in recent
              sales and need an injection of fresh ideas, partnerships, &
              strategies to take their brand to new heights. This package can be
              designed for an ad spend of anywhere from $20,000 CAD+ per month.
            </p>
          </div>
        </Products>
      </div>
    </section>
  </Layout>
);

export default ContentPage;
